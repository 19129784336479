import { Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeDocumentsService } from 'src/app/services/employee-documents.service';
import { DataService } from 'src/app/services/data.service';
import { Document } from 'src/app/models/document.model';
import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NoContentComponent } from '../../common/no-content/no-content.component';
import { LoaderComponent } from '../../common/loader/loader.component';

@Component({
  selector: 'app-employee-card-documents',
  standalone: true,
  imports: [CommonModule, NoContentComponent, LoaderComponent],
  templateUrl: './employee-card-documents.component.html',
  styleUrls: ['./employee-card-documents.component.scss']
})
export class EmployeeCardDocumentsComponent implements OnInit, OnDestroy {
  @Input() contactId = "";
  // now display only employee Funds Splitting Document.
  // in future other documents 
  loadingMassage = signal("אנא המתן... מסמכים בטעינה");
  constructor(public documentsService: EmployeeDocumentsService, public dataService: DataService) {
  }

  ngOnInit(): void {
    this.documentsService.startFetchingDocuments(this.contactId);
  }
  ngOnDestroy(): void {
    this.documentsService.stopFetchingDocuments();
  }
  getDocument(doc: Document, download = false) {
    const downloadObservable = doc.link
      ? of(doc.link)
      : from(this.documentsService.downloadFile(doc.physicalPath)).pipe(
        switchMap(blob => {
          const link = URL.createObjectURL(blob);
          doc.link = link;
          return of(link);
        })
      );
    downloadObservable.subscribe(
      link => {
        if (download) {
          const a = document.createElement("a");
          a.setAttribute("download", "myfile.pdf");
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        else {
          const newWindow = window.open(link, '_blank');
          if (newWindow) {
            newWindow.focus();
          }
        }
      },
      error => {
        console.error('Error downloading file:', error);
      }
    );
  }
}

