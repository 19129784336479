import { Injectable, OnDestroy, signal } from '@angular/core';
import { HttpHandler } from './httpHandler';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { ErrorService } from './error.service';
import { interval, merge, of, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployeeDocumentsService extends HttpHandler implements OnDestroy {

  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
  ) {
    super(_http, httpService, errorService);
  }
  private fetchSubscription: Subscription | null = null;
  private _documents = signal<Document[]>([]);
  private _isLoading = signal<boolean>(false);
  isLoading = this._isLoading.asReadonly();
  documents = this._documents.asReadonly();

  startFetchingDocuments(contactId: string) {
    // reset dcuments for employee
    this.stopFetchingDocuments();
    this._documents.set([]);

    this._isLoading.set(true); 
    this.fetchSubscription = merge(of(0), interval(60000))
      .pipe(
        switchMap(() => this.fetchEmployeeDocuments(contactId)),
        tap(response => {
          if (!response.notFinishUpload) {
            this._documents.set(response.documents);
            this._isLoading.set(false); 
            this.stopFetchingDocuments();
          }
        }),
        catchError(err => {
          this._isLoading.set(false); 
          return of(null);
        })
      ).subscribe();
  }

  stopFetchingDocuments() {
    this.fetchSubscription?.unsubscribe();
    this.fetchSubscription = null;
    this._isLoading.set(false);
  }

  fetchEmployeeDocuments(contactId: string) {
    const url = "/contact/documents/employee/" + contactId;
    return this.get(url);
  }


  downloadFile(path: string) {
    const encodedPath = encodeURIComponent(path);
    const url = `/contact/documents/getFile/${encodedPath}`;
    return this.download(url);
  }

}
