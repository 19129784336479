import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { TextService } from "../../../services/text.service";
import { NgIf, NgClass } from "@angular/common";
@Component({
    selector: "app-task-component",
    templateUrl: "./task-component.component.html",
    styleUrls: ["./task-component.component.scss"],
    standalone: true,
    imports: [NgIf, NgClass]
})
export class TaskComponentComponent implements OnInit {
  @Input() data;
  @Input() isItEmployeeTasks = true;
  name;
  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    if (this.isItEmployeeTasks) {
      this.name = this.dataService.getResourceText(
        this.textService.textSource[
          "EMPLOYEE_CARD_SCREEN_BUTTONS_OPTIONS_4_SCREEN_LABEL_1"
        ],
        this.data
      );
    } else {
      this.name = this.dataService.getResourceText(
        this.textService.textSource["MY_MISSIONS_SCREEN_ITEM_LABEL_1"],
        this.data
        
      );
    }
  }
}
